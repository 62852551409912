import { Context, Middleware } from "@nuxt/types";
import { navigateTo } from "#app";
import { useAuth0 } from "@auth0/auth0-vue";
import { useJwtStore } from "../store/jwt";
import { setSessionData } from "~/utils/SetSessionData";
import { IsUnauthenticatedRoute } from "~/utils/IsUnauthenticatedRoute";
import { useUserStore } from "@/store/user";
import { useOrgStore } from "@/store/org";

const authMiddleware: Middleware = async (context: Context) => {
  if (process.client) {
    // Don't check authentication for unprotected routes
    if (IsUnauthenticatedRoute(context.path)) {
      return;
    }

    const auth0 = useAuth0();
    const isAuthenticated = auth0.isAuthenticated.value;
    if (!isAuthenticated) {
      await auth0.loginWithRedirect();
      // NOTE: Temporarily commented out this code block for testing purposes.
      // const query = context?.query;
      // if (query && query.code && query.state) {
      //   // NOTE: Looks like we don't need to make this call (https://auth0.github.io/auth0-vue/interfaces/Auth0VueClient.html#handleRedirectCallback)
      //   // await auth0.handleRedirectCallback();
      // } else {
      //   await auth0.loginWithRedirect();
      // }
    } else {
      const jwt = await auth0.getAccessTokenSilently();
      const jwtStore = useJwtStore();
      jwtStore.updateSessionJwt(jwt);
      // always set localstorage user/org data
      const response = await setSessionData();
      if (response == "NotAuthorized") {
        // redirect to org expired logout
        if (context.fullPath != "/expired") {
          await navigateTo("/expired", {
            external: true,
          });
        }
      } else {
        if (context.fullPath === "/") {
          const path = `/search`;
          await navigateTo(path, {
            external: true,
          });
        }
      }
    }
    const userStore = useUserStore();
    userStore.setUser(JSON.parse(localStorage.getItem("user") ?? "{}"));
    const orgStore = useOrgStore();
    orgStore.setOrg(JSON.parse(localStorage.getItem("organization") ?? "{}"));
  }
};

export default authMiddleware;
