import { defineStore } from "pinia";
import NavigationState from "~/types/NavigationStateType";
import NavBarOption from "~/types/NavBarOptionType";
import routes from "~/constants/routes";

const monitorOptions = routes.filter(
  (route: any) => route.name === "Monitor",
)[0].subRoutes;
const reportsOptions = routes.filter(
  (route: any) => route.name === "Reports",
)[0].subRoutes;
const keyholeOptions = routes.filter(
  (route: any) => route.name === "Keyhole",
)[0].subRoutes;
const contactsOptions = routes.filter(
  (route: any) => route.name === "Contacts",
)[0].subRoutes;
const manageOptions = routes.filter((route: any) => route.name === "Manage")[0]
  .subRoutes;
const settingsOptions = routes.filter(
  (route: any) => route.name === "Settings",
)[0].subRoutes;

type navBarGroup = "Monitor" | "Reports" | "Contacts" | "Settings" | "Manage";

export const useNavigationStore = defineStore("navigation", {
  state: (): NavigationState => ({
    activeSideBarOption: null,
    activeNavBarOption: null,
    activeNavBarOptions: null,
    activeGroup: "Monitor",
  }),
  actions: {
    setActiveSideBarOption(activeSideBarOption: string | null) {
      this.activeSideBarOption = activeSideBarOption;
    },
    setActiveNavBarOption(activeNavBarOption: number | null) {
      this.activeNavBarOption = activeNavBarOption;
    },
    setActiveNavBarOptions(activeNavBarOptions: Array<NavBarOption> | null) {
      this.activeNavBarOptions = activeNavBarOptions;
    },
    setActiveNavBarGroup(input: navBarGroup) {
      const optionMap = {
        Monitor: monitorOptions,
        Reports: reportsOptions,
        Keyhole: keyholeOptions,
        Contacts: contactsOptions,
        Manage: manageOptions,
        Settings: settingsOptions,
      };

      this.activeGroup = input;
      this.activeNavBarOptions = optionMap[input] || [];

      this.activeNavBarOption =
        this.activeNavBarOptions && this.activeNavBarOptions.length > 0
          ? this.activeNavBarOptions[0].id
          : null;
    },

    setDisplay(id: number, newDisplay: boolean) {
      if (this.activeNavBarOptions) {
        const option = this.activeNavBarOptions.find(
          (option) => option.id === id,
        );
        if (option) {
          option.display = newDisplay;
        }
      }
    },
  },
});
