import { UseFetchOptions, useFetch, useRuntimeConfig } from "#app";
import { useAuth0 } from "@auth0/auth0-vue";
import { useLogout } from "./useLogout";
import { useJwtStore } from "@/store/jwt";

type useFetchType = typeof useFetch;

export const useApiCall: useFetchType = async (
  path: string,
  options: UseFetchOptions<Response, any> = {},
  basePath?: string,
  api: string = "rails",
  skipAuthorization: boolean = false,
) => {
  // The majority of routes are prefixed with `/api/v1`, so this function defaults to that.
  // An optional param can be passed in to override this if needed.
  if (process.client) {
    if (!basePath) {
      basePath = "/api/v1";
    }
    const config = useRuntimeConfig();
    const auth0 = useAuth0();
    const jwtStore = useJwtStore();
    const isCMRequest = api === "cm";

    let jwt: string | null = null;

    if (!skipAuthorization && auth0 && auth0.isAuthenticated) {
      jwt = await auth0.getAccessTokenSilently();
      if (!jwt) {
        // If our JWT doesn't exist, we're not authenticated and need to be logged out
        useLogout(auth0);
      }
    }

    // Let the proxy do it's thing for cm_api requests
    options.baseURL = isCMRequest
      ? `${window.location.origin}/${basePath}`
      : String(config.public.API_HOST) + basePath;
    if (!skipAuthorization) {
      let auth = jwtStore.sessionJwt != null ? jwtStore.sessionJwt : jwt;
      if (!isCMRequest) {
        auth = `Bearer ${auth}`;
      }
      options.headers = {
        Authorization: auth || "",
        Source: "Medusa",
      };
    }
    const fetchOptions: UseFetchOptions<Response, any> = options;
    if (
      skipAuthorization ||
      (auth0 && auth0.isAuthenticated) ||
      jwtStore.sessionJwt != null
    ) {
      const resp = await useFetch(path, fetchOptions);
      return resp;
    }
  }
};
