import { useRuntimeConfig } from "nuxt/app";
import { useApiCall } from "~/composables/useApiCall";
import { RailsApiResponseType } from "~/types/RailsApiResponseType";

const extractData = (response: any): RailsApiResponseType | null => {
  return response && response.data && response.data._rawValue
    ? response.data._rawValue
    : null;
};

export const setSessionData = async (): Promise<String> => {
  try {
    const config = useRuntimeConfig();

    const sessionResponse = await useApiCall("/session_data", {
      method: "GET",
    });

    if (
      sessionResponse.error.value?.statusCode === 500 &&
      String(sessionResponse.error.value?.data.exception).includes(
        "NotAuthorizedError",
      )
    ) {
      // organization expired
      return "NotAuthorized";
    }

    if (
      sessionResponse.error.value?.statusCode === 403 &&
      String(sessionResponse.error.value?.data.errors[0].detail).includes(
        "expired",
      )
    ) {
      // organization expired
      return "NotAuthorized";
    }

    // Use the helper function to safely extract data
    const sessionData: RailsApiResponseType | null =
      extractData(sessionResponse);

    if (sessionData) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: sessionData.data.id,
          ...sessionData.data.attributes,
        }),
      );

      if (config.public.CM_API_HOST && config.public.CM_PARTNER_TOKEN) {
        // Store the CM environment variables in localStorage to be used in cm-reports
        const cmConfig = {
          cm_api_host: config.public.CM_API_HOST,
          cm_partner_token: config.public.CM_PARTNER_TOKEN,
        };
        localStorage.setItem("cmConfig", JSON.stringify(cmConfig));
      }

      localStorage.setItem("language", sessionData.data.attributes.language);

      if (sessionData.data.relationships.current_search_dashboard.data) {
        localStorage.setItem(
          "currentSearchDashboard",
          JSON.stringify(
            sessionData.data.relationships.current_search_dashboard,
          ),
        );
      }

      if (sessionData.data.relationships.current_profile.data) {
        localStorage.setItem(
          "currentProfile",
          JSON.stringify(sessionData.data.relationships.current_profile),
        );
      }

      // Check if `included` exists and has at least one item
      if (sessionData.included && sessionData.included.length > 0) {
        localStorage.setItem(
          "organization",
          JSON.stringify(sessionData.included[0]),
        );
      }
      return "Ok";
    }

    return "SessionDataMissing";
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error fetching session data:", error);
    // Handle the error as needed
    return String(error);
  }
};
