import { useSearchStore } from "~/store/search";
import { useSearchFiltersStore } from "~/store/searchFilter";
import RequestQuery from "~/types/RequestQueryType";
import { requestQueryDateParser } from "~/utils/RequestQueryDateParser";

export const useRequestQuery = (
  searchType: string,
  replaceSearchQuery: string | null = null,
  profileId: string | null = null,
) => {
  const savedSearchStore = useSearchStore();
  const searchFiltersStore = useSearchFiltersStore();
  const currentSearchFilter = searchFiltersStore.getCurrentSearchFilter;

  const addArticleSearchFields = (query: RequestQuery) => {
    query.data.id = null;
    query.data.attributes.limit = savedSearchStore.limit;
    query.data.attributes.page = savedSearchStore.page;
    query.data.attributes.sort = savedSearchStore.sort;
  };

  const addChartSearchFields = (query: RequestQuery) => {
    query.data.attributes.aggregation_fields =
      currentSearchFilter.aggregation_fields;
    query.data.attributes.aggregation_scale =
      currentSearchFilter.aggregation_scale;
  };

  const addCsvArticleSearchFields = (query: RequestQuery) => {
    query.data.attributes.limit = 1000;
    query.data.attributes.page = 1;
    query.data.attributes.search_type = 2;
    query.data.attributes.sort = savedSearchStore.sort;
  };

  const addTopicSearchFields = (query: RequestQuery) => {
    query.data.attributes.aggregation_scale =
      currentSearchFilter.aggregation_scale;
    delete query.data.attributes.search_type;
  };

  const getRelationships = () => {
    let relationshipObj = {};
    if (savedSearchStore.querySearchType !== 14) {
      relationshipObj = {
        profile: {
          data: {
            type: "profiles",
            id: profileId || savedSearchStore.selectedNestedSearch.id,
          },
        },
      };
    }

    return relationshipObj;
  };

  const combineExcludedDomains = () => {
    // Destructure relevant properties to avoid repetitive access
    const { excludedDomains } = searchFiltersStore;
    const { filter_included_domains: includedDomains } = currentSearchFilter;

    // Define a helper function to combine domains
    const combineDomains = (domain: keyof typeof excludedDomains) => {
      const excluded = excludedDomains[domain] || "";
      const included = includedDomains[domain] || "";

      // Combine and filter out empty values, then join with '|'
      return [excluded, included].filter(Boolean).join("|");
    };

    // Create an object containing combined domains for each type
    const combinedDomains = {
      web: combineDomains("web"),
      print: combineDomains("print"),
      tv: combineDomains("tv"),
      radio: combineDomains("radio"),
    };

    return combinedDomains;
  };

  const RequestQuery: RequestQuery = {
    data: {
      attributes: {
        search_type: savedSearchStore.querySearchType,
        search_text: replaceSearchQuery || savedSearchStore.searchText, // update store searchText whenever generated
        filter_date_start: requestQueryDateParser(savedSearchStore.startDate),
        filter_date_end: requestQueryDateParser(savedSearchStore.endDate),
        filter_pagerank_min: currentSearchFilter.filter_pagerank_min,
        filter_pagerank_max: currentSearchFilter.filter_pagerank_max,
        filter_languages: currentSearchFilter.filter_languages,
        filter_countries: currentSearchFilter.filter_countries,
        filter_relevance: currentSearchFilter.filter_relevance,
        filter_article_sentiment: currentSearchFilter.filter_article_sentiment,
        filter_included_domains: currentSearchFilter.filter_included_domains,
        filter_excluded_domains: combineExcludedDomains(),
        filter_keywords: savedSearchStore.filterKeywords,
        exclude_financial_news: currentSearchFilter.exclude_financial_news,
        exclude_press_releases: currentSearchFilter.exclude_press_releases,
        exclude_market_research: currentSearchFilter.exclude_market_research,
        show_only_subscribed_markets:
          currentSearchFilter.show_only_subscribed_markets,
        web_media_type: currentSearchFilter.web_media_type,
        print_media_type: currentSearchFilter.print_media_type,
        tv_media_type: currentSearchFilter.tv_media_type,
        radio_media_type: currentSearchFilter.radio_media_type,
      },
      relationships: getRelationships(),
      type: searchType,
    },
  };

  if (searchType === "chart-searches") {
    addChartSearchFields(RequestQuery);
  } else if (searchType === "csv-article-searches") {
    addCsvArticleSearchFields(RequestQuery);
  } else if (searchType === "topic-searches") {
    addTopicSearchFields(RequestQuery);
  } else {
    addArticleSearchFields(RequestQuery);
  }
  return RequestQuery;
};
