import { default as indexvC1vs3dEkKMeta } from "/unified-client/pages/charts/index.vue?macro=true";
import { default as indexi4DZmJzQ8aMeta } from "/unified-client/pages/expired/index.vue?macro=true";
import { default as indexXPUch3jAo5Meta } from "/unified-client/pages/explore/index.vue?macro=true";
import { default as indexO72oYXe3IKMeta } from "/unified-client/pages/fetch-article-alert/index.vue?macro=true";
import { default as indexp2Nmukip8hMeta } from "/unified-client/pages/fetch-email-digest/index.vue?macro=true";
import { default as indexog2LuypSk0Meta } from "/unified-client/pages/index.vue?macro=true";
import { default as indexaZkpptIckMMeta } from "/unified-client/pages/logIn/index.vue?macro=true";
import { default as indexlO2RrASVxIMeta } from "/unified-client/pages/logout/index.vue?macro=true";
import { default as articlesKRIe85Jn42Meta } from "/unified-client/pages/manage/articles.vue?macro=true";
import { default as indexEPnpU7h6qiMeta } from "/unified-client/pages/manage/index.vue?macro=true";
import { default as indexz84hvswehoMeta } from "/unified-client/pages/mentions/index.vue?macro=true";
import { default as indexMrD89eK7xZMeta } from "/unified-client/pages/password/edit/index.vue?macro=true";
import { default as indexx2DgItUtELMeta } from "/unified-client/pages/pro/index.vue?macro=true";
import { default as indexB3lDp1RpV9Meta } from "/unified-client/pages/reports/index.vue?macro=true";
import { default as indexUVDchSUB2MMeta } from "/unified-client/pages/search/index.vue?macro=true";
import { default as indexTXGRLEKTpoMeta } from "/unified-client/pages/target-media-lists/index.vue?macro=true";
import { default as indexr6yZRyD8LnMeta } from "/unified-client/pages/topics/index.vue?macro=true";
export default [
  {
    name: indexvC1vs3dEkKMeta?.name ?? "charts",
    path: indexvC1vs3dEkKMeta?.path ?? "/charts",
    meta: indexvC1vs3dEkKMeta || {},
    alias: indexvC1vs3dEkKMeta?.alias || [],
    redirect: indexvC1vs3dEkKMeta?.redirect,
    component: () => import("/unified-client/pages/charts/index.vue").then(m => m.default || m)
  },
  {
    name: indexi4DZmJzQ8aMeta?.name ?? "expired",
    path: indexi4DZmJzQ8aMeta?.path ?? "/expired",
    meta: indexi4DZmJzQ8aMeta || {},
    alias: indexi4DZmJzQ8aMeta?.alias || [],
    redirect: indexi4DZmJzQ8aMeta?.redirect,
    component: () => import("/unified-client/pages/expired/index.vue").then(m => m.default || m)
  },
  {
    name: indexXPUch3jAo5Meta?.name ?? "explore",
    path: indexXPUch3jAo5Meta?.path ?? "/explore",
    meta: indexXPUch3jAo5Meta || {},
    alias: indexXPUch3jAo5Meta?.alias || [],
    redirect: indexXPUch3jAo5Meta?.redirect,
    component: () => import("/unified-client/pages/explore/index.vue").then(m => m.default || m)
  },
  {
    name: indexO72oYXe3IKMeta?.name ?? "fetch-article-alert",
    path: indexO72oYXe3IKMeta?.path ?? "/fetch-article-alert",
    meta: indexO72oYXe3IKMeta || {},
    alias: indexO72oYXe3IKMeta?.alias || [],
    redirect: indexO72oYXe3IKMeta?.redirect,
    component: () => import("/unified-client/pages/fetch-article-alert/index.vue").then(m => m.default || m)
  },
  {
    name: indexp2Nmukip8hMeta?.name ?? "fetch-email-digest",
    path: indexp2Nmukip8hMeta?.path ?? "/fetch-email-digest",
    meta: indexp2Nmukip8hMeta || {},
    alias: indexp2Nmukip8hMeta?.alias || [],
    redirect: indexp2Nmukip8hMeta?.redirect,
    component: () => import("/unified-client/pages/fetch-email-digest/index.vue").then(m => m.default || m)
  },
  {
    name: indexog2LuypSk0Meta?.name ?? "index",
    path: indexog2LuypSk0Meta?.path ?? "/",
    meta: indexog2LuypSk0Meta || {},
    alias: indexog2LuypSk0Meta?.alias || [],
    redirect: indexog2LuypSk0Meta?.redirect,
    component: () => import("/unified-client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexaZkpptIckMMeta?.name ?? "logIn",
    path: indexaZkpptIckMMeta?.path ?? "/logIn",
    meta: indexaZkpptIckMMeta || {},
    alias: indexaZkpptIckMMeta?.alias || [],
    redirect: indexaZkpptIckMMeta?.redirect,
    component: () => import("/unified-client/pages/logIn/index.vue").then(m => m.default || m)
  },
  {
    name: indexlO2RrASVxIMeta?.name ?? "logout",
    path: indexlO2RrASVxIMeta?.path ?? "/logout",
    meta: indexlO2RrASVxIMeta || {},
    alias: indexlO2RrASVxIMeta?.alias || [],
    redirect: indexlO2RrASVxIMeta?.redirect,
    component: () => import("/unified-client/pages/logout/index.vue").then(m => m.default || m)
  },
  {
    name: articlesKRIe85Jn42Meta?.name ?? "manage-articles",
    path: articlesKRIe85Jn42Meta?.path ?? "/manage/articles",
    meta: articlesKRIe85Jn42Meta || {},
    alias: articlesKRIe85Jn42Meta?.alias || [],
    redirect: articlesKRIe85Jn42Meta?.redirect,
    component: () => import("/unified-client/pages/manage/articles.vue").then(m => m.default || m)
  },
  {
    name: indexEPnpU7h6qiMeta?.name ?? "manage",
    path: indexEPnpU7h6qiMeta?.path ?? "/manage",
    meta: indexEPnpU7h6qiMeta || {},
    alias: indexEPnpU7h6qiMeta?.alias || [],
    redirect: indexEPnpU7h6qiMeta?.redirect,
    component: () => import("/unified-client/pages/manage/index.vue").then(m => m.default || m)
  },
  {
    name: indexz84hvswehoMeta?.name ?? "mentions",
    path: indexz84hvswehoMeta?.path ?? "/mentions",
    meta: indexz84hvswehoMeta || {},
    alias: indexz84hvswehoMeta?.alias || [],
    redirect: indexz84hvswehoMeta?.redirect,
    component: () => import("/unified-client/pages/mentions/index.vue").then(m => m.default || m)
  },
  {
    name: indexMrD89eK7xZMeta?.name ?? "password-edit",
    path: indexMrD89eK7xZMeta?.path ?? "/password/edit",
    meta: indexMrD89eK7xZMeta || {},
    alias: indexMrD89eK7xZMeta?.alias || [],
    redirect: indexMrD89eK7xZMeta?.redirect,
    component: () => import("/unified-client/pages/password/edit/index.vue").then(m => m.default || m)
  },
  {
    name: indexx2DgItUtELMeta?.name ?? "pro",
    path: indexx2DgItUtELMeta?.path ?? "/pro",
    meta: indexx2DgItUtELMeta || {},
    alias: indexx2DgItUtELMeta?.alias || [],
    redirect: indexx2DgItUtELMeta?.redirect,
    component: () => import("/unified-client/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexB3lDp1RpV9Meta?.name ?? "reports",
    path: indexB3lDp1RpV9Meta?.path ?? "/reports",
    meta: indexB3lDp1RpV9Meta || {},
    alias: indexB3lDp1RpV9Meta?.alias || [],
    redirect: indexB3lDp1RpV9Meta?.redirect,
    component: () => import("/unified-client/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: indexUVDchSUB2MMeta?.name ?? "search",
    path: indexUVDchSUB2MMeta?.path ?? "/search",
    meta: indexUVDchSUB2MMeta || {},
    alias: indexUVDchSUB2MMeta?.alias || [],
    redirect: indexUVDchSUB2MMeta?.redirect,
    component: () => import("/unified-client/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexTXGRLEKTpoMeta?.name ?? "target-media-lists",
    path: indexTXGRLEKTpoMeta?.path ?? "/target-media-lists",
    meta: indexTXGRLEKTpoMeta || {},
    alias: indexTXGRLEKTpoMeta?.alias || [],
    redirect: indexTXGRLEKTpoMeta?.redirect,
    component: () => import("/unified-client/pages/target-media-lists/index.vue").then(m => m.default || m)
  },
  {
    name: indexr6yZRyD8LnMeta?.name ?? "topics",
    path: indexr6yZRyD8LnMeta?.path ?? "/topics",
    meta: indexr6yZRyD8LnMeta || {},
    alias: indexr6yZRyD8LnMeta?.alias || [],
    redirect: indexr6yZRyD8LnMeta?.redirect,
    component: () => import("/unified-client/pages/topics/index.vue").then(m => m.default || m)
  }
]