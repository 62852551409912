import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faCheck,
  faCaretDown,
  faCaretUp,
  faEllipsis,
  faInfo,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faSort,
  faSortUp,
  faSortDown,
  faTrashCan,
  faDash,
  faMagnifyingGlass,
  faSlidersUp,
  faHome,
  faFileLines,
  faUserGroup,
  faCircle,
  faArrowRightToBracket,
  faChevronLeft,
  faChevronRight,
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faLinkSimple,
  faRectangleVertical,
  faCircleCheck,
} from "@fortawesome/pro-solid-svg-icons"; // fas
import {
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faArrowDownArrowUp,
  faArrowRotateRight,
  faArrowUpWideShort,
  faMeh,
  faFrown,
  faSmile,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  faCircleExclamation,
  faComputer,
  faNewspaper,
  faTv,
  faRadio,
  faBullhorn,
  faXmark,
  faEyeSlash,
  faList,
  faFileChartColumn,
  faEquals,
  faCalendarRange,
  faPenToSquare,
  faShapes,
  faCirclesOverlap,
  faPlus,
  faKeySkeleton,
  faArrowsRotate,
  faTrashCan as farTrashCan,
  faObjectExclude,
  faGlobe,
  faTvRetro,
} from "@fortawesome/pro-regular-svg-icons"; // far
import {
  faTimesCircle,
  faBookUser,
  faCog,
  faFileChartLine,
  faUserChart,
  faSignOut,
  faSliders,
  faCircleQuestion,
  faPen,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/pro-light-svg-icons"; // fal
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
  faTimesCircle, // fal
  faSmile,
  faMeh,
  faFrown,
  faInfoCircle,
  faChevronUp,
  faChevronDown,
  fasChevronDown,
  fasChevronUp,
  faCircleExclamation,
  faComputer,
  faNewspaper,
  faTv,
  faRadio,
  faTwitter,
  faBullhorn,
  faXmark,
  faEyeSlash,
  faList,
  faFileChartColumn,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faArrowRotateRight,
  faEquals,
  faCalendarRange,
  faShapes,
  faPenToSquare,
  faTrashCan, // fas
  faUpRightAndDownLeftFromCenter, // fas
  faDownLeftAndUpRightToCenter, // fas
  faCaretDown, // fas
  faCaretUp, // fas
  faAngleUp, // fas
  faAngleDown, // fas
  faInfo, // fas
  faEllipsis, // fas
  faSortUp, // fas
  faSortDown, // fas
  faSort, // fas
  faAngleLeft, // fas
  faAngleRight, // fas
  faCheck, // fas
  faDash, // fas
  faMagnifyingGlass, // fas
  faSliders, // fas
  faHome, // fas
  faFileLines, // fas
  faUserGroup, // fas
  faSlidersUp, // fas
  faCircle, // fas
  faCircleQuestion, // fas
  faArrowRightToBracket, // fas
  faRectangleVertical, // fas
  faCircleCheck, // fas
  faBookUser,
  faCog,
  faFileChartLine,
  faUserChart,
  faSignOut,
  faCirclesOverlap,
  faPlus,
  faChevronLeft,
  faChevronRight,
  faArrowsRotate,
  faLinkSimple,
  faKeySkeleton,
  farTrashCan,
  faArrowDownArrowUp,
  faPen,
  faArrowUpWideShort,
  faObjectExclude,
  faGlobe,
  faTvRetro,
);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon, {});
});
