import { defineStore } from "pinia";
import Org from "~/types/OrgType";
import { useMediaListStore } from "~/store/mediaList";

type State = {
  org: Org | null;
};

export const useOrgStore = defineStore("org", {
  state: (): State => ({
    org: null,
  }),
  getters: {
    getOrg(state) {
      return state.org;
    },
    getWordPlay(state) {
      return state.org?.attributes.plan_features.enableWordplay;
    },
    getTMLLimit(state) {
      return state.org?.attributes.plan_limits.tmlTotal;
    },
    getTMLDomainsLimit(state) {
      return state.org?.attributes.plan_limits.tmlDomains;
    },
    getLicenseCountries(state) {
      return state.org?.attributes.license_countries;
    },
    hasPrintMediaAccess(state) {
      return state.org?.attributes.plan_features.printMedia;
    },
    hasBroadcastMediaAccess(state) {
      return (
        state.org?.attributes.plan_features.broadcastMedia ||
        state.org?.attributes.plan_features.usaBroadcastMedia
      );
    },
    hasArticleSentiment(state) {
      return state.org?.attributes.plan_features.articleSentiment;
    },
    isTrialAccount(state) {
      return state.org?.attributes.trial_account;
    },
    hasSocialTracking(state) {
      if (state.org?.attributes)
        return state.org?.attributes.plan_features.socialTracking;
      return false;
    },
    hasExceededTMLLimit(state) {
      const mediaListStore = useMediaListStore();

      return state.org?.attributes.plan_limits.tmlTotal
        ? mediaListStore.mediaLists.length >=
            state.org?.attributes.plan_limits.tmlTotal
        : false;
    },
    hasNLALicense(state): boolean {
      if (state.org?.attributes) {
        const printLicenses = state.org?.attributes.print_licenses;
        if (Array.isArray(printLicenses)) {
          return printLicenses.includes("NLA");
        }
      }
      return false;
    },
    monthlyLimit(state): number {
      return state.org?.attributes.licensed_article_limit || 0;
    },
    dailyLimit(): number {
      if (this.monthlyLimit > 0) {
        return Math.round(this.monthlyLimit / 30);
      }
      return this.monthlyLimit;
    },
  },
  actions: {
    setOrg(org: Org) {
      this.org = org;
    },
  },
});
