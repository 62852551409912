const extractHostname = function extractHostname(url: string) {
  if (!url) {
    return "";
  }
  try {
    if (!url.includes("http://") && !url.includes("https://")) {
      url = `https://${url}`;
    }
    const newUrl = new URL(url);
    let hostname = newUrl.hostname;
    hostname = hostname.replace("www.", "");
    return hostname;
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.error("Invalid URL: ", e.message);
    return "";
  }
};

export { extractHostname };
