import { useRuntimeConfig } from "nuxt/app";
import { Context, Middleware } from "@nuxt/types";
import routes from "~/constants/routes";
import { useNavigationStore } from "@/store/navigation";
import { useOrgStore } from "@/store/org";
import { useUserStore } from "@/store/user";
import { IsUnauthenticatedRoute } from "~/utils/IsUnauthenticatedRoute";
import { checkPlan } from "~/utils/PlanFeatureCheck";

const navigationMiddleware: Middleware = (context: Context) => {
  if (process.server) return;

  const config = useRuntimeConfig();
  const orgStore = useOrgStore();
  const userStore = useUserStore();

  const fsOptions = {
    displayName: userStore.getUser?.name,
    email: userStore.getUser?.email,
    orgName: orgStore.getOrg?.attributes?.name,
    orgId: orgStore.getOrg?.id,
    timezone_str: orgStore.getOrg?.attributes?.time_zone,
    isSuperAdmin_bool: userStore.getUser?.role_name === "super_admin",
    userSignUpDate: userStore.getUser?.created_at,
    orgSignUpDate: orgStore.getOrg?.attributes?.created_at,
    orgEndDate: orgStore.getOrg?.attributes?.end_date,
    trialAccount: orgStore.getOrg?.attributes?.trial_account,
    salesRegion: orgStore.getOrg?.attributes?.sales_region,
    appVersion: "1.0", // TODO: this should be set properly once we setup versioning process
  };

  // init FS if we're on prod
  if (process.client && !IsUnauthenticatedRoute(context.path)) {
    if (
      config.public.IS_PROD &&
      typeof FS !== "undefined" &&
      typeof FS === "function"
    ) {
      FS("setIdentity", {
        uid: `360_${userStore.getUser?.id}`,
        properties: fsOptions,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error("FullStory not available to initialize");
    }
  }

  if (process.client && !IsUnauthenticatedRoute(context.path)) {
    // TODO: setup intercom init
    const intercomOptions = {
      api_base: "https://api-iam.intercom.io",
      app_id: "du7ohuq1",
      name: userStore.getUser?.name,
      user_id: userStore.getUser?.id,
      user_hash: userStore.getUser?.intercom_hash,
      email: userStore.getUser?.email,
      orgName: orgStore.getOrg?.attributes?.name,
      orgId: orgStore.getOrg?.id,
      planName: orgStore.getOrg?.attributes?.plan_name,
      timeZone: orgStore.getOrg?.attributes?.time_zone,
      userSignUpDate: userStore.getUser?.created_at,
      orgSignUpDate: orgStore.getOrg?.attributes?.created_at,
      orgEndDate: orgStore.getOrg?.attributes?.end_date,
      trialAccount: orgStore.getOrg?.attributes?.trial_account,
      digitalLicenses: JSON.parse(
        JSON.stringify(orgStore.getOrg?.attributes?.digital_licenses) || "[]",
      ),
      printLicenses: JSON.parse(
        JSON.stringify(orgStore.getOrg?.attributes?.print_licenses) || "[]",
      ),
      licenseRegion: orgStore.getOrg?.attributes?.license_region || "",
      licenseArticleLimit:
        orgStore.getOrg?.attributes?.licensed_article_limit || "250",
      internalAccount: orgStore.getOrg?.attributes?.internal_account || false,
      subscriptionId:
        orgStore.getOrg?.relationships?.subscriptions?.data?.[0]?.id || null,
      // subscriptionStatus: subscriptionStatus
      // ^ Ember magically gets this one, we should fetch it in the org store
      browserLanguage: navigator.language,
      hide_default_launcher: true,
    };

    const intercom = window.Intercom;

    if (config.public.IS_PROD && intercom) {
      // TODO: We need to fetch a bit more data for this.
      // const activeSubscription = orgStore.getOrg?.relationships?.subscriptions?.data?.[0];

      // let subscriptionStatus = null;

      // if (activeSubscription) {

      //   subscriptionStatus = activeSubscription.status;
      // const subscriptionAddons = await activeSubscription
      //   .get("subscriptionAddons")
      //   .mapBy("chargebeeId");
      // subscriptionAddons.forEach((item) => {
      //   let name = null;
      //   switch (item) {
      //     case "social-listening":
      //       name = "SocialListening";
      //       break;
      //     case "verified-authors-usa":
      //       name = "USVCDB";
      //       break;
      //     case "verified-authors-gbr":
      //       name = "GlobalVCDB";
      //       break;
      //     case "global-broadcast-media-monitoring":
      //       name = "GlobalBroadcast";
      //       break;
      //     case "broadcast-monitoring":
      //       name = "USBroadcast";
      //       break;
      //   }
      //   if (item.includes("twitter-amp-")) {
      //     name = "TwitterAmp";
      //   }
      //   if (name) {
      //     intercomOptions[`subscriptionAddon_${name}`] = true;
      //   }
      // });
      // }

      // intercomOptions["subscriptionStatus"] = subscriptionStatus;

      intercom("boot", {
        ...intercomOptions,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error("Intercom not available to initialize");
    }
  }

  const navigationStore = useNavigationStore();

  let path = context.fullPath.includes("?")
    ? context.fullPath.substring(0, context.fullPath.indexOf("?"))
    : context.fullPath;

  // Handle edge cases
  if (context.fullPath.includes("graph=articles")) {
    path = `${path}?graph=articles`;
  } else if (context.fullPath.includes("graph=topics")) {
    path = `${path}?graph=topics`;
  }

  let flag = false;
  routes.every((route) => {
    return route.subRoutes.every((subRoute) => {
      if (subRoute?.path !== "/" && path === subRoute?.path) {
        flag = true;
        navigationStore.setActiveSideBarOption(route.name);
        navigationStore.setActiveNavBarOption(subRoute.id);
        navigationStore.setActiveNavBarOptions(route.subRoutes);
        return false;
      }
      return true;
    });
  });

  // This block handles the edge case where we hit the search edit page
  // from the montior tab of the sidebar
  if (path.includes("searches/edit")) {
    navigationStore.setActiveSideBarOption(routes[3].name);
    navigationStore.setActiveNavBarOption(routes[3].subRoutes[4].id);
    navigationStore.setActiveNavBarOptions(routes[3].subRoutes);
  }

  // This block handles the edge case where settings page doesnt display sidebars
  if (path.includes("#/settings/user")) {
    navigationStore.setActiveSideBarOption(routes[5].name);
    navigationStore.setActiveNavBarOption(routes[5].subRoutes[0].id);
    navigationStore.setActiveNavBarOptions(routes[5].subRoutes);
  }

  // If flag is false, no route was set and we should fall back to defaults
  if (!flag && path === "/pro/#/monitor") {
    navigationStore.setActiveSideBarOption(routes[0].name);
    navigationStore.setActiveNavBarOption(1);
    navigationStore.setActiveNavBarOptions(routes[0].subRoutes);
  }

  const activeRoute = navigationStore.activeNavBarOptions;
  activeRoute?.forEach((subRoute) => {
    // enable nav bar options based on plan features
    if (
      process.client &&
      checkPlan(
        localStorage.getItem("organization") ?? "{}",
        subRoute.translationKey,
      )
    ) {
      navigationStore.setDisplay(subRoute.id, true);
    }
  });
};

export default navigationMiddleware;
